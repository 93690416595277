import React, { Component } from 'react'
import { connect } from 'react-redux';
export function AlertaCookie() {
    return (
        <>
            <div className="relative bg-gray-100">
                <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
                    <div className="pr-6 sm:px-16 sm:text-left">
                        <p className="font-medium align-right text-gray-600">
                            <span className="md:hidden">We announced a new product!</span>
                            <span
                                className="hidden md:inline">Nossos sites e serviços, você concorda com o uso de cookies por nossa parte conforme estabelecido na nossa Política de privacidade.</span>
                        </p>
                    </div>
                    <div
                        className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
                        <button type="button"
                                className="flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white">
                            <span className="sr-only">Dismiss</span>

                            <svg className="h-6 w-6 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}