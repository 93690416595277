import React, { Component } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";
import {CabecalhoEmBreve} from "../componente/cabecalhoEmBreve";
export function PoliticaCookiePage({ history }) {
    return (
        <>
            <CabecalhoEmBreve/>

            <div className="overflow-hidden bg-white">
                <div className="relative mx-auto max-w-xl px-1 lg:max-w-7xl lg:px-1">

                    <div class="relative">
                        <div class="mx-auto text-lg mb-40 ">
                            <h1 className="text-4xl font-black tracking-tight  text-[#49000e] sm:text-4xl md:text-4xl"> Política de Cookies</h1>
                            <div className="mt-4">
                                <h2><span class="mt-6 block text-left text-xl text-base font-extrabold leading-8 tracking-tight text-gray-600 ">Introdução</span></h2>
                                <p class="text-base  text-regular mt-2 leading-8 text-gray-500">A presente Política de Cookies complementa os Termos de Uso e Política de Privacidade disponíveis aos usuários de nossos serviços pelo portal e mobile da Atendes.Nesse sentido, o nosso objetivo é garantir que todos os usuários se familiarizem com todas as nossas práticas de proteção e privacidade de dados e, desta forma, tenham todas as informações necessárias para navegar corretamente.</p>
                                <h2><span className="mt-6 block text-left text-xl text-base font-extrabold leading-8 tracking-tight text-gray-600 ">E afinal o que são esses cookies?</span></h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Os cookies são arquivos de texto criados pelos websites que podem armazenar dados relativos aos hábitos de navegação, preferências de busca e outras informações, ajudando a personalizar seu acesso.</p>
                                <h2><span className="mt-6 block text-left text-xl text-base font-extrabold leading-8 tracking-tight text-gray-600 ">Como isso funciona na prática?</span></h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Os cookies funcionam para armazenar as suas preferências e escolhas. Ajudando que o website lembre das suas ações ao longo do tempo e tornando a sua experiência de navegação a mais agradável possível.</p>
                                <h2><span className="mt-6 block text-left text-xl text-base font-extrabold leading-8 tracking-tight text-gray-600 ">Por que usamos cookies?</span></h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Usamos os cookies para facilitar e melhorar a navegação, reconhecendo as preferências dos usuários e adaptando o website aos seus interesses e necessidades.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Com os cookies, entendemos como é a interação do usuário com os nossos conteúdos e, desta forma, aprendemos com o seu comportamento, oferecemos um conteúdo personalizado e melhoramos a sua experiência.</p>
                                <p className="text-base  font-bold mt-2 text-xl leading-8 text-gray-500"> Quais cookies coletamos?</p>
                                <p className="text-base  mt-2 leading-8 text-gray-500"> São cookies indispensáveis para manter suas preferências de navegação e sem eles não é  possível a navegação.</p>
                                <p className="text-base  font-bold mt-2 text-xl leading-8 text-gray-500"> E não é perigoso ter minhas informações armazenadas?</p>
                                <p className="text-base  mt-2 leading-8 text-gray-500"> Não é perigoso. Todas as suas informações coletadas em nosso site, como número de conta, senhas e outras, são protegidas por nosso sistema de segurança.</p>
                                <p className="text-base  font-bold mt-2 text-xl leading-8 text-gray-500"> Não gosto de cookies, como faço para desativá-los?</p>
                                <p className="text-base  mt-2 leading-8 text-gray-500"> • possível restringir, bloquear ou excluir os cookies por meio das preferências do seu navegador. Em cada navegador, a operação é diferente. Para saber como gerenciar, veja as definições sobre cookies em cada um dos navegadores:</p>
                                <p className="text-base italic  mt-2 leading-8 text-gray-500">Internet Explorer / Firefox / Google Chrome / Safari /Brave</p>
                                <p className="text-base  mt-2 leading-8 text-gray-500">Não se esqueça que os cookies permitem que você tenha uma melhor experiência em nossos sites e, sem eles, sua navegação pode se tornar limitada e algumas funcionalidades dos sites podem ficar comprometidas.</p>

                                <p className="text-base text-xl  font-bold mt-2 leading-8 text-gray-500">Disposições Finais</p>
                                <p className="text-base  mt-2 leading-8 text-gray-500">Alertamos que esta Política de Cookies poderá ser modificada a qualquer tempo pela Atendes. As mudanças entrarão em vigor logo após a publicação, e você será avisado. Ao continuar a navegação, você concorda com as alterações.</p>
                                <p className="text-base  mt-2 leading-8 text-gray-500">Se qualquer alteração exigir seu consentimento, faremos uma solicitação para que você forneça esse novo consentimento. Aconselhamos que visite nossa Política de Cookies com frequência para manter-se informado sobre quaisquer atualizações ou alterações.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}


