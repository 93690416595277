import React from "react";
import {
  NavLink,
  Link,
  Routes,
  Route,
    Navigate,
  useParams,
  useNavigate
} from "react-router-dom";

import  {HomePage} from "./paginas/Home";
import  {AfiliadoPage} from "./paginas/Afiliados";
import  {CasosUsosPage} from "./paginas/CasosUsos";
import  {ProdutoPage} from "./paginas/Produtos";
import  {DesenvolvedorPage} from "./paginas/Desenvolvedor";
import  {Error404Page} from "./paginas/Error404";
import  {PoliticaPrivacidadePage} from "./paginas/PoliticaPrivacidade";
import  {PoliticaCookiePage} from "./paginas/PoliticaCookies";
import  {EmBreve} from "./paginas/EmBreve";
import {TermoUsoPage} from "./paginas/TermoUso";

function AppRoutes() {
  return (
      <Routes>
          <Route path="/" element={<EmBreve />} />
          <Route path="/afiliados" element={<AfiliadoPage />} />
          <Route path="/casos-sucesso" element={<CasosUsosPage />} />
          <Route path="/produtos" element={<ProdutoPage />} />
          <Route path="/desenvolvedor" element={<DesenvolvedorPage />} />
          <Route path="/politica-privacidade" element={<PoliticaPrivacidadePage />} />
          <Route path="/termo-uso" element={<TermoUsoPage />} />
          <Route path="/politica-cookie" element={<PoliticaCookiePage />} />
          <Route path="pagina-nao-encontrada-404" element={<Error404Page/>}/>
          <Route path="*" element={<Navigate to="/pagina-nao-encontrada-404" replace />} />

      </Routes>
  );
}

export default function App() {
  return (
        <AppRoutes />
  );
}
