import React, { Component } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";
export function ProdutoPage({ history }) {
    return (
        <>
            <Cabecalho/>
            <div className="relative overflow-hidden bg-white pt-16 pb-32">
                <div className="relative">
                    <div
                        className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
                        <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
                            <div>
                                <div className="mt-6">
                                    <h1 className="text-6xl font-black tracking-tight  text-[#49000e] sm:text-5xl md:text-6xl">Stay on top of
                                        customer support</h1>
                                    <p className="mt-4 text-lg text-gray-500">Semper curabitur ullamcorper posuere nunc
                                        sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar
                                        laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
                                        viverra dui tellus ornare pharetra.</p>
                                    <div className="mt-6">
                                        <a href="#"
                                           className="inline-flex rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700">Get
                                            started</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 border-t border-gray-200 pt-6">
                                <blockquote>
                                    <div>
                                        <p className="text-base text-gray-500">&ldquo;Cras velit quis eros eget rhoncus
                                            lacus ultrices sed diam. Sit orci risus aenean curabitur donec aliquet. Mi
                                            venenatis in euismod ut.&rdquo;</p>
                                    </div>
                                    <footer className="mt-3">
                                        <div className="flex items-center space-x-3">
                                            <div className="flex-shrink-0">
                                                <img className="h-6 w-6 rounded-full"
                                                     src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                                     alt=""/>
                                            </div>
                                            <div className="text-base font-medium text-gray-700">Marcia Hill, Digital
                                                Marketing Manager
                                            </div>
                                        </div>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0">
                            <div className="-mr-48 pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                                <img
                                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                    src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                                    alt="Inbox user interface"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-24">
                    <div
                        className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
                        <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
                            <div>

                                <div className="mt-6">
                                    <h2 className="text-3xl font-black tracking-tight  text-[#49000e] sm:text-5xl md:text-6xl">Better understand
                                        your customers</h2>
                                    <p className="mt-4 text-lg text-gray-500">Semper curabitur ullamcorper posuere nunc
                                        sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar
                                        laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus
                                        viverra dui tellus ornare pharetra.</p>
                                    <div className="mt-6">
                                        <a href="#"
                                           className="inline-flex rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700">Get
                                            started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
                            <div className="-ml-48 pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                                <img
                                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                                    src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
                                    alt="Customer profile user interface"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-500">
                <div className="mx-auto max-w-4xl px-6 py-24 sm:py-32 lg:max-w-7xl lg:px-8 lg:py-40">
                    <h2 className="text-4xl font-bold tracking-tight text-white">Inbox support built for
                        efficiency.</h2>
                    <p className="mt-6 max-w-3xl text-lg leading-8 text-indigo-200">Ac tincidunt sapien vehicula erat
                        auctor pellentesque rhoncus. Et magna sit morbi lobortis. Blandit aliquam sit nisl euismod
                        mattis in.</p>
                    <div className="mt-20 grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
                        <div>
                            <div>
          <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">

              <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"/>
            </svg>
          </span>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold leading-8 text-white">Unlimited Inboxes</h3>
                                <p className="mt-2 text-base leading-7 text-indigo-200">Ac tincidunt sapien vehicula
                                    erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</p>
                            </div>
                        </div>

                        <div>
                            <div>
          <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">

              <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
            </svg>
          </span>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold leading-8 text-white">Manage Team Members</h3>
                                <p className="mt-2 text-base leading-7 text-indigo-200">Ac tincidunt sapien vehicula
                                    erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</p>
                            </div>
                        </div>

                        <div>
                            <div>
          <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">

              <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
            </svg>
          </span>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold leading-8 text-white">Spam Report</h3>
                                <p className="mt-2 text-base leading-7 text-indigo-200">Ac tincidunt sapien vehicula
                                    erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</p>
                            </div>
                        </div>

                        <div>
                            <div>
          <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">

              <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
            </svg>
          </span>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold leading-8 text-white">Compose in Markdown</h3>
                                <p className="mt-2 text-base leading-7 text-indigo-200">Ac tincidunt sapien vehicula
                                    erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</p>
                            </div>
                        </div>

                        <div>
                            <div>
          <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">

              <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
            </svg>
          </span>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold leading-8 text-white">Team Reporting</h3>
                                <p className="mt-2 text-base leading-7 text-indigo-200">Ac tincidunt sapien vehicula
                                    erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</p>
                            </div>
                        </div>

                        <div>
                            <div>
          <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">

              <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"/>
            </svg>
          </span>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold leading-8 text-white">Saved Replies</h3>
                                <p className="mt-2 text-base leading-7 text-indigo-200">Ac tincidunt sapien vehicula
                                    erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</p>
                            </div>
                        </div>

                        <div>
                            <div>
          <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">

              <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"/>
            </svg>
          </span>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold leading-8 text-white">Email Commenting</h3>
                                <p className="mt-2 text-base leading-7 text-indigo-200">Ac tincidunt sapien vehicula
                                    erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</p>
                            </div>
                        </div>

                        <div>
                            <div>
          <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">

              <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"/>
            </svg>
          </span>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-lg font-semibold leading-8 text-white">Connect with Customers</h3>
                                <p className="mt-2 text-base leading-7 text-indigo-200">Ac tincidunt sapien vehicula
                                    erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Rodape/>

        </>
    );
}


