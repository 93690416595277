import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";

 export function CasosUsosPage({ history }) {
     return (
            <>
                <Cabecalho/>
                <div className="relative bg-white px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
                    <div className="absolute inset-0">
                        <div className="h-1/3 bg-white sm:h-2/3"></div>
                    </div>
                    <div className="relative mx-auto max-w-7xl">

                        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover"
                                         src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                                         alt=""/>
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            <a href="#" className="hover:underline">Article</a>
                                        </p>
                                        <a href="#" className="mt-2 block">
                                            <p className="text-xl font-semibold text-gray-900">Boost your conversion
                                                rate</p>
                                            <p className="mt-3 text-base text-gray-500">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Architecto accusantium praesentium eius,
                                                ut atque fuga culpa, similique sequi cum eos quis dolorum.</p>
                                        </a>
                                    </div>

                                </div>
                            </div>

                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover"
                                         src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                                         alt=""/>
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            <a href="#" className="hover:underline">Video</a>
                                        </p>
                                        <a href="#" className="mt-2 block">
                                            <p className="text-xl font-semibold text-gray-900">How to use search engine
                                                optimization to drive sales</p>
                                            <p className="mt-3 text-base text-gray-500">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Velit facilis asperiores porro quaerat
                                                doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi.,
                                                tempore temporibus quo laudantium.</p>
                                        </a>
                                    </div>

                                </div>
                            </div>

                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover"
                                         src="https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                                         alt=""/>
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            <a href="#" className="hover:underline">Case Study</a>
                                        </p>
                                        <a href="#" className="mt-2 block">
                                            <p className="text-xl font-semibold text-gray-900">Improve your customer
                                                experience</p>
                                            <p className="mt-3 text-base text-gray-500">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae
                                                magni placeat saepe molestiae, sed excepturi cumque corporis perferendis
                                                hic.</p>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover"
                                         src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                                         alt=""/>
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            <a href="#" className="hover:underline">Article</a>
                                        </p>
                                        <a href="#" className="mt-2 block">
                                            <p className="text-xl font-semibold text-gray-900">Boost your conversion
                                                rate</p>
                                            <p className="mt-3 text-base text-gray-500">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Architecto accusantium praesentium eius,
                                                ut atque fuga culpa, similique sequi cum eos quis dolorum.</p>
                                        </a>
                                    </div>

                                </div>
                            </div>

                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover"
                                         src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                                         alt=""/>
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            <a href="#" className="hover:underline">Video</a>
                                        </p>
                                        <a href="#" className="mt-2 block">
                                            <p className="text-xl font-semibold text-gray-900">How to use search engine
                                                optimization to drive sales</p>
                                            <p className="mt-3 text-base text-gray-500">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Velit facilis asperiores porro quaerat
                                                doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi.,
                                                tempore temporibus quo laudantium.</p>
                                        </a>
                                    </div>

                                </div>
                            </div>

                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover"
                                         src="https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                                         alt=""/>
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            <a href="#" className="hover:underline">Case Study</a>
                                        </p>
                                        <a href="#" className="mt-2 block">
                                            <p className="text-xl font-semibold text-gray-900">Improve your customer
                                                experience</p>
                                            <p className="mt-3 text-base text-gray-500">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae
                                                magni placeat saepe molestiae, sed excepturi cumque corporis perferendis
                                                hic.</p>
                                        </a>
                                    </div>

                                </div>
                            </div>

                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover"
                                         src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                                         alt=""/>
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            <a href="#" className="hover:underline">Article</a>
                                        </p>
                                        <a href="#" className="mt-2 block">
                                            <p className="text-xl font-semibold text-gray-900">Boost your conversion
                                                rate</p>
                                            <p className="mt-3 text-base text-gray-500">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Architecto accusantium praesentium eius,
                                                ut atque fuga culpa, similique sequi cum eos quis dolorum.</p>
                                        </a>
                                    </div>

                                </div>
                            </div>

                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover"
                                         src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                                         alt=""/>
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            <a href="#" className="hover:underline">Video</a>
                                        </p>
                                        <a href="#" className="mt-2 block">
                                            <p className="text-xl font-semibold text-gray-900">How to use search engine
                                                optimization to drive sales</p>
                                            <p className="mt-3 text-base text-gray-500">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Velit facilis asperiores porro quaerat
                                                doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi.,
                                                tempore temporibus quo laudantium.</p>
                                        </a>
                                    </div>

                                </div>
                            </div>

                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover"
                                         src="https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                                         alt=""/>
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-indigo-600">
                                            <a href="#" className="hover:underline">Case Study</a>
                                        </p>
                                        <a href="#" className="mt-2 block">
                                            <p className="text-xl font-semibold text-gray-900">Improve your customer
                                                experience</p>
                                            <p className="mt-3 text-base text-gray-500">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae
                                                magni placeat saepe molestiae, sed excepturi cumque corporis perferendis
                                                hic.</p>
                                        </a>
                                    </div>

                                </div>
                            </div>




                        </div>
                    </div>
                </div>

                <Rodape/>
            </>
        );
}


