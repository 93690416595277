import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";

export function AfiliadoPage({ history }) {
    return (
        <>
            <Cabecalho/>
            <div className="overflow-hidden bg-white">
                <div className="relative mx-auto max-w-xl px-1 lg:max-w-7xl lg:px-1">


                    <div className="relative">
                        <h2 className="text-5xl font-bold tracking-tight text-[#49000e] sm:text-5xl">
                            Seja um afiliado</h2>
                        <p className="mt-4 max-w-3xl text-left text-xl text-gray-500">Lorem ipsum dolor sit
                            amet consectetur adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in,
                            accusamus quisquam.</p>
                    </div>

                    <div className="relative mt-2 lg:mt-24 lg:grid lg:grid-cols-2 mb-20 bg-gray-100 lg:items-center bg-lg:gap-8">
                        <div className="relative bg-gray-100 px-10 py-20">
                            <h2 className="text-base font-semibold  text-[#49000e]">Valuable Metrics</h2>
                            <p className="mt-3 text-3xl font-bold tracking-tight text-gray-700">Get actionable data that will
                                help grow your business</p>
                            <p className="mt-5 text-lg text-gray-600">Rhoncus sagittis risus arcu erat lectus bibendum. Ut
                                in adipiscing quis in viverra tristique sem. Ornare feugiat viverra eleifend fusce orci in
                                quis amet. Sit in et vitae tortor, massa. Dapibus laoreet amet lacus nibh integer quis. Eu
                                vulputate diam sit tellus quis at.</p>
                            <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                                <p>
                                    <span className="block text-2xl font-bold text-gray-700">8K+</span>
                                    <span className="mt-1 block text-base text-gray-600"><span
                                        className="font-medium text-gray-700">Companies</span> use laoreet amet lacus nibh integer quis.</span>
                                </p>

                                <p>
                                    <span className="block text-2xl font-bold text-gray-700">25K+</span>
                                    <span className="mt-1 block text-base text-gray-600"><span
                                        className="font-medium text-gray-700">Countries around the globe</span> lacus nibh integer quis.</span>
                                </p>

                                <p>
                                    <span className="block text-2xl font-bold text-gray-700">98%</span>
                                    <span className="mt-1 block text-base text-gray-600"><span
                                        className="font-medium text-gray-700">Customer satisfaction</span> laoreet amet lacus nibh integer quis.</span>
                                </p>

                                <p>
                                    <span className="block text-2xl font-bold text-gray-700">12M+</span>
                                    <span className="mt-1 block text-base text-gray-600"><span
                                        className="font-medium text-gray-700">Issues resolved</span> lacus nibh integer quis.</span>
                                </p>
                            </div>
                        </div>

                        <div className="relative   bg-gray-100 lg:mt-0" aria-hidden="true">

                            <img className="relative mx-auto" width="490"
                                 src="https://tailwindui.com/img/features/feature-example-1.png" alt=""/>
                        </div>
                    </div>



                </div>
            </div>




            <Rodape/>
        </>
    );
}


