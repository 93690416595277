import React, { Component } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";
import {CabecalhoEmBreve} from "../componente/cabecalhoEmBreve";
export function PoliticaPrivacidadePage({ history }) {
    return (
        <>
            <CabecalhoEmBreve/>

            <div className="overflow-hidden bg-white">
                <div className="relative mx-auto max-w-xl px-1 lg:max-w-7xl lg:px-1">

                    <div class="relative">
                        <div class="mx-auto text-lg mb-40 ">
                            <h1 className="text-4xl font-black tracking-tight  text-[#49000e] sm:text-4xl md:text-4xl">POLÍTICA DE PRIVACIDADE</h1>
                            <p className="mt-4 text-base  text-regular mt-2 leading-8 text-gray-500">Em conformidade com as melhores práticas e baseados em nossa filosofia de transparência e respeito aos clientes, apresentamos os princípios fundamentais da Política de Privacidade On-line da Atendes(chamada “Política de Privacidade”).</p>
                            <p className="mt-4 text-base  text-regular mt-2 leading-8 text-gray-500">Estas são as diretrizes adotadas pela Atendes sobre a coleta, armazenamento, uso e todas as outras formas de tratamento de informações pessoais dos clientes/usuários e internautas, para acesso e uso de nossos serviços através da plataforma móvel, aplicativo e outros meios de contato da Atendes (denominados "Serviços").</p>

                            <div className="mt-4">
                                <h2><span className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">1. Definições</span></h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                    <ul className="mt-2 list-disc ml-20">
                                        <li><span className="font-extrabold">Agentes de tratamento:</span> o controlador e o operador.</li>
                                        <li><span className="font-extrabold">Controlador:</span> pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.</li>
                                        <li><span className="font-extrabold">Dado Pessoal:</span> toda e qualquer informação relacionada a pessoa natural identificada ou identificável.</li>
                                        <li><span className="font-extrabold">Dado Pessoal Sensível:</span> dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</li>
                                        <li><span className="font-extrabold">Operador:</span> pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador;</li>
                                        <li><span className="font-extrabold">Titular:</span> pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.</li>
                                        <li><span className="font-extrabold">Tratamento:</span> todas as ações realizadas com dados pessoais, incluindo coleta, produção, recepção, classificação, uso, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência e extração.</li>
                                        <li><span className="font-extrabold">Criptografia:</span> é o nome dado ao processo de codificação de informações. As informações são codificadas (embaralhadas) no ponto de origem e decodificadas no destino, dificultando assim a sua decifração durante o tráfego na internet.</li>
                                        <li><span className="font-extrabold">Cookies:</span> são arquivos de texto armazenados pelo navegador (como o Internet Explorer, Firefox, Chrome ou Safari) em seu computador ou telefone celular. Eles têm como objetivo identificar o computador ou telefone celular, personalizar os acessos e obter dados de acesso, como páginas navegadas ou links clicados. Cada cookie é atribuído individualmente a cada computador e não podem ser usados para executar programas, infectar computadores com vírus, trojans, etc. e só podem ser lidos pelo servidor que os enviou.</li>
                                    </ul>
                                </p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                Sem prejuízo de outras definições constantes nesta Política, os seguintes termos terão os significados a eles designados abaixo:
                                    <ul className="mt-2 list-none ml-20">
                                        <li><span className="font-extrabold">"internauta"</span> - todo aquele que, de alguma forma, acessa os canais da Atendes, sendo cliente ou não.</li>
                                        <li><span className="font-extrabold">"cliente"</span> - todo aquele que utiliza os produtos e/ou serviços oferecidos pelos canais da Atendes, pagos ou não.</li>
                                        <li><span className="font-extrabold">"usuário"</span> - todo aquele que se cadastrar no portal da Atendes e receber uma identificação individual e exclusiva.</li>
                                    </ul>
                                </p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">2. Quem é a responsável pelo tratamento dos dados pessoais (Controlador)?</span>
                                </h2>

                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">É a Atendes , empresa inscrita no CNPJ nº 35.225.380/0001-47, com sede na avenida Paulista , 171 PAVMT04 - Bela Vista – CEP 01311-000, São Paulo-SP.</p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">3. Para quais finalidades os dados pessoais são tratados?</span>
                                </h2>


                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                    De acordo com a Lei Geral de Proteção de Dados (Lei nº 13.709/18), a Atendes realiza o tratamento de dados pessoais dos seus clientes com finalidades específicas e em conformidade com as bases legais estabelecidas pela referida lei, tais como: para o cumprimento das obrigações legais e regulatórias, para o exercício regular de direitos e para a proteção do crédito, bem como sempre que necessário para a execução dos contratos firmados com seus clientes ou para atender aos interesses legítimos da Atendes, de seus clientes ou de terceiros. Para qualquer outra finalidade, para a qual o consentimento do titular deva ser obtido, o tratamento estará condicionado à manifestação livre, informada e inequívoca do titular.
                                </p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                A Atendes, como controlador de dados de acordo com a legislação aplicável, poderá processar, coletar, armazenar e compartilhar com as sociedades sob controle direto ou indireto do Atendes , sempre observando as disposições da Lei Geral de Proteção de Dados (Lei nº 13.709/18), os dados pessoais e informações cadastrais, financeiras e de operações ativas e passivas e serviços contratados, com as seguintes finalidades: (i) garantir maior segurança e prevenir fraudes; (ii) assegurar sua adequada identificação, qualificação e autenticação; (iii) aperfeiçoar o atendimento e os produtos e serviços prestados; (iv) fazer ofertas de produtos e serviços adequados e relevantes aos seus interesses e necessidades de acordo com o seu perfil; e (v) outras hipóteses baseadas em finalidades legítimas, como apoio e promoção de atividades da Atendes ou para a prestação de serviços que beneficiem os clientes.
                                </p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                    A Atendes, enquanto controlador de dados de acordo com a legislação aplicável, poderá compartilhar infraestrutura, sistemas e tecnologia com outras empresas  com as sociedades sob controle direto ou indireto do Atendes, a fim de fornecer uma experiência inovadora, relevante, consistente e segura em relação a todos os produtos e serviços das empresas. Além disso, processamos informações sobre você nas empresas, sempre de acordo com a lei aplicável e com esta política de privacidade.
                                </p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                    É importante observar que novos serviços online disponibilizados pela Atendes estarão sujeitos à Política de Privacidade vigente à época de sua utilização
                                </p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                Queremos oferecer a melhor experiência durante a sua navegação em nossos sites e também na internet.
                                </p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">4. Tratamos quais dados pessoais?</span>
                                </h2>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">5. Uso de cookies</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                A Atendes utiliza cookies com o objetivo de fornecer ao usuário uma melhor experiência em nosso portal. Os cookies são arquivos de pequeno tamanho armazenados em dispositivos do usuário, enquanto ele navega na internet, e ajudam a armazenar suas preferências e a personalizar o acesso.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                Com o uso de cookies, a Atendes poderá coletar, tratar, armazenar e compartilhar informações com parceiros, para fins como garantir maior segurança e prevenir fraudes, assegurar a adequada identificação e autenticação, realizar análises de risco de crédito, oferecer produtos e serviços adequados e relevantes, entre outros fins baseados em interesses legítimos,para:
                                    <ul className="mt-2 list-disc ml-20">
                                        <li>melhorar a eficiência e velocidade da navegação.</li>
                                        <li>melhorar a usabilidade, experiência e interatividade ao utilizar nossos portais, sites, aplicativos, e-mails e enquanto navega na internet</li>
                                        <li>fornecer ofertas e informações mais precisas e relevantes às suas necessidades e interesses;</li>
                                        <li>melhorar a eficiência e continuidade da comunicação com você;</li>
                                        <li>atender às suas perguntas e solicitações;</li>
                                        <li>realizar pesquisas de comunicação e marketing para melhorar nossos produtos e serviços e coletar estatísticas gerais.</li>
                                    </ul>
                                </p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                   Lembrando que você pode controlar os cookies através das configurações do seu navegador e desativar para usar nossos Serviços. Isso afetara algumas funcionalidades dos nossos sites.
                                </p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                    <a className="text-blue-400" target="_blank" href="https://privacy.microsoft.com/pt-BR/windows-10-microsoft-edge-and-privacy">Internet Explorer</a>  /  <a className="text-blue-400" target="_blank" href="https://support.mozilla.org/pt-BR/kb/desative-cookies-no-firefox-parar-rastreamento">Firefox</a> /  <a className="text-blue-400" target="_blank" href="https://support.google.com/accounts/answer/61416?hl=pt-BR">Google Chrome</a> /  <a className="text-blue-400" target="_blank" href="https://support.apple.com/kb/PH21411?viewlocale=pt_BR&locale=en_US">Safari</a>
                                </p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">6. Tratamento de informações pessoais</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes usa medidas avançadas para proteger informações pessoais de seus clientes e Serviços enquanto fornece Serviços online</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes se compromete a não divulgar as informações pessoais dos usuários de seus Serviços, incluindo informações sensíveis, exceto nas hipóteses especificadas na Política de Privacidade da empresa. Essas informações incluem, mas não se limitam a, nome completo, endereço, contato, RG, CPF, biometria, informações financeiras e preferências de acesso.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">As informações pessoais dos usuários são coletadas através dos canais da empresa  e as armazena com rigorosos padrões de segurança e privacidade, seguindo princípios éticos e legais. Se o usuário fornecer essas informações, elas serão utilizadas apenas para as definidas em contrato de prestação de serviços e nos Termos e Condições de Uso.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500"> As informações pessoais dos usuários, coletadas através dos canais da empresa, com o objetivo de enviar publicidade direcionada, por meio de e-mails ou outros meios de comunicação, contendo informações sobre a Atendes,  parceiros comerciais e/ou prestadores de serviço, para oferta de produtos e serviços de interesse do usuário. No entanto, o usuário tem o direito de, a qualquer momento, inclusive no momento da fornecimento das informações pessoais, informar à Atendes, por meio dos canais de comunicação disponíveis, a falta de interesse em receber tais anúncios, incluindo e-mails (opt-out), neste caso, a Atendes interromperá o envio destes o mais breve possível. Para cancelar a inscrição, consulte as instruções de opt-out presentes no rodapé dos nossos e-mails.</p>

                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">7. Transferência Internacional</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                Os dados pessoais coletados através do uso dos nossos Serviços serão processados pela Atendes.

                                    A Atendes tem sede no Brasil, mas pode tratar dados pessoais em outros países através de parcerias com empresas que, por sua vez, serão obrigadas a cumprir com esta Política de Privacidade.</p>

                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">8. Como respondemos a requerimentos legais?</span>
                                </h2>
                                    <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                Em caso de requerimento por parte de autoridades com base em leis vigentes, a Atendes compartilhará as informações pessoais solicitadas. Nestes casos, forneceremos apenas as informações estritamente necessárias para cumprimento do requerimento ou para nossos programas de integridade.
                                    </p>
<h2></h2>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">9. Exercício de direitos</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                Em conformidade com a regulamentação aplicável, especialmente a Lei Geral de Proteção de Dados (Lei nº 13.709/2018), a Atendes respeita e garante a você, a possibilidade de apresentação de solicitações baseadas nos seguintes direitos:
                                    <ul className="mt-2 list-decimal ml-20">
                                        <li>confirmação da existência de tratamento de seus dados pessoais;</li>
                                        <li>acesso aos dados pessoais tratados;</li>
                                        <li>correção de dados incompletos, inexatos ou desatualizados;</li>
                                        <li>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a Lei;</li>
                                        <li>portabilidade dos dados para outro fornecedor;</li>
                                        <li>eliminação dos dados tratados com o seu consentimento, exceto quando exigido por lei;</li>
                                        <li>informação sobre as entidades públicas ou privadas com as quais a Atendes compartilhou seus dados;</li>
                                        <li>informação sobre a possibilidade de não fornecer o seu consentimento e as consequências disso;</li>
                                        <li>revogação do consentimento;</li>
                                        <li>oposição a tratamentos realizados com base em hipóteses de dispensa de consentimento, caso haja descumprimento à Lei Geral de Proteção de Dados;</li>
                                        <li>revisão de decisões tomadas exclusivamente com base em tratamento automatizado de dados pessoais que afetem seus interesses, respeitando os segredos comerciais e industriais da Atendes, como, mas não se limitando, aquelas relacionadas à prevenção de fraudes ou à execução de contrato.</li>
                                    </ul>

                                </p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                A Atendes irá fazer todos os esforços possíveis para atender às solicitações de exercício de direitos em um curto período de tempo. No entanto, mesmo quando houver uma solicitação de exclusão, serão respeitados os prazos de armazenamento mínimos de informações de usuários de aplicações da internet estabelecidos pela legislação brasileira e outras disposições legais aplicáveis.
                                </p>

                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">10. Como tratamos as suas informações de forma segura?</span>
                                </h2>

                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes restringe o acesso às informações pessoais coletadas, armazenadas ou tratadas por profissionais autorizados e somente para fins relacionados à prestação de seus serviços. Qualquer organização ou indivíduo contratado para prestar serviços de apoio também deve cumprir as políticas de segurança internas e o código de ética da empresa.</p>



                                    <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes poderá compartilhar as informações pessoais que tenha coletado nas seguintes situações:
                                        <ul className="mt-2 list-disc ml-20">
                                            <li>quando houver uma obrigação legal de fazê-lo, como em resposta a ordens judiciais ou ações de autoridades competentes;</li>
                                                <li>para seus parceiros comerciais e prestadores de serviços, a fim de atender às solicitações dos usuários;</li>
                                                <li>para órgãos de proteção e defesa de crédito e outros prestadores de serviços autorizados pela Atendes para proteger seus direitos e créditos;</li>
                                                <li>para órgãos que administram cadastros de consumidores;</li>
                                                <li> aos seus controladores, às empresas por ele controladas, as empresas a ele coligadas ou por qualquer forma associadas, no Brasil ou no exterior;</li>
                                        </ul>
                                    </p>
                                    <p className="text-base  text-regular mt-2 leading-8 text-gray-500"> A Atendes se esforça para garantir que as informações compartilhadas com os clientes sejam precisas e completas, contando com rigorosos controles de verificação das informações fornecidas. Seu papel é revisar as informações, valores e informativos e notificar o usuário sobre qualquer divergência nas informações fornecidas</p>
                                    <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes não será responsável por quaisquer danos decorrentes de violações ou falhas nas medidas de segurança da internet por parte de terceiros, como "hackers" ou "crackers", que possam levar ao vazamento de informações pessoais. Além disso, a Atendes só repassará informações de identificação individual a terceiros, exceto aqueles já mencionados anteriormente, mediante autorização prévia do cliente/usuário/internauta. Informações que não sejam de identificação individual, como dados demográficos, poderão ser compartilhadas com anunciantes, fornecedores, patrocinadores e parceiros, com o objetivo de melhorar a qualidade dos produtos e serviços oferecidos pela Atendes.</p>
                                    <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Aos terceiros que, porventura receberem da Atendes informações de qualquer natureza, sobre os internautas que acessam o seu Portal, cabe igualmente, a responsabilidade de zelar pelo sigilo e segurança de referidas informações.</p>
                                    <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes se compromete a garantir a segurança e privacidade das informações coletadas. No entanto, é importante que os clientes/usuários/internautas também tenham cuidado ao fornecer seus dados pessoais ao acessar a internet, somente compartilhando-os em operações seguras e nunca compartilhando suas informações de login. Além disso, é importante desconectar sua conta do portal Atendes sempre que deixar de usá-la, especialmente se estiver usando um computador compartilhado.</p>
                                    <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes garante que as informações contidas em seus sites são legais e estão de acordo com as leis e regulamentos sobre direitos autorais, marcas registradas e patentes. Qualquer tipo de modificação, cópia, reprodução ou uso comercial dessas informações só é permitido com o consentimento prévio e explícito da Atendes.</p>
                                    <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes não é responsável por quaisquer danos ou problemas decorrentes de atrasos, interrupções ou bloqueios nas transmissões de dados que possam ocorrer na Internet.</p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">11. Extensão dos efeitos</span>
                                </h2>

                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Política de Privacidade da Atendes se aplica apenas às informações pessoais e informações pessoais sensíveis fornecidas pelos clientes/usuários/internautas para o uso de seus produtos e serviços</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Política de Privacidade da Atendes só se aplica às informações fornecidas pelos clientes/usuários/internautas para uso dos seus produtos e serviços. Ela não se aplica a outros serviços online fora da Atendes, incluindo sites vinculados por links ou outros recursos tecnológicos, e tampouco a qualquer outro site conhecido ou utilizado pela Atendes.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes alerta os usuários de que outros sites que não os disponibilizados pela Atendes, incluindo aqueles vinculados por meio de links ou outros recursos tecnológicos, podem possuir políticas de privacidade diferentes ou até mesmo não possuir políticas de privacidade. A Atendes não se responsabiliza por qualquer violação aos direitos de privacidade dos usuários que possa ocorrer em sites fora do seu controle.</p>

                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">12. Interpretação dos Termos</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">As palavras e termos não definidos nesta Política de Privacidade, sejam escritos em português ou em outra língua, bem como os termos técnicos ou financeiros que possam ser utilizados ao longo da vigência deste acordo, para identificar ações ou obrigações das partes, devem ser entendidos e interpretados de acordo com os conceitos internacionalmente aceitos.</p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">13. Atendimento a Solicitações e Reclamações</span>
                                </h2>

                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Se você precisar de ajuda ou quiser fazer uma reclamação, entre em contato com nosso Centro de Atendimento. Para confirmar sua identidade, solicitaremos algumas informações pessoais.</p>

                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Em caso de solicitações ou reclamações, por favor, entre em contato com nossa Central de Atendimento. Durante o atendimento, pode ser necessário fornecer alguns dados pessoais para verificação de identidade. Adicionalmente, outras informações podem ser requisitadas para confirmar sua identidade, se houver dúvidas sobre a veracidade das informações fornecidas. Para questões relacionadas à LGPD, você pode entrar em contato com nosso encarregado de Dados através do e-mail dpo@atendes.com.br.</p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">14. Disposições Gerais</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Omissões ou tolerâncias pelas partes em exigir o rigoroso cumprimento desta Política de Privacidade ou direitos legais não significam renúncia ou mudança, e não afetam o exercício de quaisquer direitos previstos. Mesmo em caso de uma disposição ser considerada inválida, as demais disposições da Política de Privacidade continuarão em pleno vigor e um termo válido será substituído ao inválido, refletindo a nossa intenção tanto quanto possível.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A falta de exigência rigorosa e completa desta Política de Privacidade e/ou dos direitos decorrentes da lei, por parte das partes, não deverá ser considerada como uma mudança ou renúncia, nem prejudicará o exercício de quaisquer direitos previstos nesta Política de Privacidade, que podem ser exercidos integralmente a qualquer momento.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Em caso de verificação da nulidade de uma cláusula, as demais cláusulas desta Política de Privacidade continuarão em pleno vigor, e uma cláusula válida substituirá a cláusula nula, refletindo nossa intenção o mais aproximadamente possível.</p>
                                <h3><span
                                    className="mt-6 block text-base text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">Publicidade em E-mail marketing</span>
                                </h3>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">As mensagens publicitárias enviadas em formato HTML podem incluir códigos que possibilitam a personalização das mensagens de acordo com as preferências específicas do cliente/usuário registrado na Atendes e a produção de relatórios sobre a visualização das mensagens, incluindo o número de vezes em que o e-mail foi aberto e o número de cliques realizados na mensagem. Essas informações serão utilizadas de maneira genérica e agregada para produzir relatórios sobre o envio de mensagens. Tais relatórios podem ser fornecidos aos anunciantes como indicadores estatísticos da eficácia das campanhas, sem revelar informações pessoais dos clientes/usuários.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Em nenhuma hipótese a Atendes divulgará esses dados de forma individualizada, ou seja, não haverá identificação do cliente/usuário ou de seus hábitos de acesso e leitura de e-mails. Ainda que coletivamente, os dados só podem ser utilizados para os fins previstos nesta Política de Privacidade.</p>
                                <h3><span
                                    className="mt-6 block text-base text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">Proteção dos dados transacionais</span>
                                </h3>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">As transações de pagamento realizadas pela Atendes são processadas em um ambiente seguro e certificado por uma empresa especializada em segurança de dados para a internet. As informações confidenciais necessárias para o processamento do pagamento são protegidas por meio de criptografia. O ícone de "Cadeado Fechado" na barra de status indica que as informações transmitidas são protegidas pela criptografia.</p>

                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Adicionalmente, a Atendes implementa medidas de segurança que impedem a coleta do número e da data de validade do cartão de crédito, bem como de outras informações confidenciais para pagamento. Esses dados são fornecidos diretamente às instituições financeiras e não são armazenados nem utilizados para qualquer outra finalidade.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Em algumas áreas do Portal Atendes, são coletadas informações pessoais e/ou de registro (tais como nome completo ou razão social, endereço, telefones, etc.), necessárias para a navegação ou utilização dos serviços disponíveis. Para proteção dessas informações, as informações pessoais e/ou de registro coletadas pelo Portal Atendes são criptografadas em todas as páginas de coleta de dados (como na adesão a um plano) e nas áreas seguras do site, onde um usuário e senha são solicitados. Este processo é certificado pela Amazon, uma certificadora de servidores web, garantindo comunicações e transações seguras.</p>



                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A privacidade e a confidencialidade dos negócios realizados e das informações associadas a esses negócios no Portal Atendes são garantidas ao usuário, sendo acessíveis somente por ele. Tais informações são restritas e não serão divulgadas a terceiros sem a autorização expressa do usuário.</p>


                                <h3><span
                                    className="mt-6 block text-base text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">Identificação do Usuário</span>
                                </h3>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Ao efetuar o cadastro no Portal Atendes para fins de adquirir produtos ou serviços oferecidos no mesmo, cada usuário será atribuído uma identificação exclusiva e única. Esta identificação será exigida e verificada em todos os acessos futuros ao Portal. Em termos jurídicos, a mencionada identificação serve como uma assinatura eletrônica, comprovando a concordância do usuário com qualquer proposta realizada através deste Portal.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A identificação do usuário é única, intransferível e submetida a criptografia antes de ser transmitida ao servidor da Atendes. A senha é armazenada no banco de dados, criptografada e tornada irreversível.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes tem o direito de verificar as informações pessoais fornecidas pelo cliente/usuário/internauta, mediante consulta a órgãos públicos, empresas especializadas, cadastros restritivos ou centrais de risco. As informações obtidas por meio dessas consultas serão tratadas com sigilo e confidencialidade pela Atendes.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">O cliente/usuário é responsável pela veracidade, precisão, validade e autenticidade das informações pessoais fornecidas e compromete-se a mantê-las atualizadas. A Atendes não é responsável por informações pessoais falsas ou imprecisas inseridas por outros clientes/usuários/internautas, não obstante o disposto acima.</p>
                                <h3><span
                                    className="mt-6 block text-base text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">Alterações na política</span>
                                </h3>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes poderá modificar esta Política de Privacidade a qualquer momento, seja em razão de alterações na legislação ou nos serviços, ou devido a novas ferramentas tecnológicas, ou ainda, sempre que, a seu exclusivo critério, essas modificações sejam necessárias. Por isso, é recomendado que se leia periodicamente esta Política de Privacidade.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A utilização dos serviços oferecidos pela Atendes por qualquer cliente/usuário implicará na expressa aceitação dos termos e condições da Política de Privacidade em vigor na data de sua utilização.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes aconselha que aqueles clientes/usuários que não concordam com a Política de Privacidade vigente não utilize os serviços deste Portal.</p>
                                <h3><span
                                    className="mt-6 block text-base text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">Dúvidas e sugestões</span>
                                </h3>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Caso haja qualquer dúvida ou sugestão sobre esta Política de Privacidade, entre em contato conosco através do Fale Conosco.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Caso se perceba que esta Política de Privacidade não esteja sendo cumprida, a não conformidade detectada deverá ser apontada ao responsável por meio do Fale Conosco.</p>

                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">15. Lei aplicável e resolução de conflitos</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Toda e qualquer controvérsia oriunda dos termos expostos na presente Política de Privacidade serão solucionados de acordo com a lei brasileira, sendo competente o foro da cidade de São Paulo, Estado de São Paulo, com exclusão de qualquer outro por mais privilegiado que seja.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Fica claro, ainda, que a utilização de Serviços e as ordens comandadas fora do território brasileiro, ou ainda as decorrentes de operações iniciadas no exterior podem estar sujeitas também à legislação e jurisdição das autoridades dos países onde forem comandadas ou iniciadas.</p>

                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">16. Dispositivos Móveis</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A fim de assegurar uma utilização eficiente dos aplicativos da Atendes, sem que haja prejuízo de seu uso, é necessário que o usuário conceda as autorizações descritas abaixo, de acordo com o disposto legalmente.</p>
                                <h3><span
                                    className="mt-6 block text-base text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">a) Autorização de Localização</span>
                                </h3>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">É necessário que o usuário autorize o acesso à sua localização para fins de validação da cidade em que ele se encontra, visando a personalização da utilização dos aplicativos da Atendes.</p>

                                <h3><span
                                    className="mt-6 block text-base text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">b) Autorização de Telefone</span>
                                </h3>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes requer autorização para armazenar o ID dos dispositivos móveis dos usuários com o objetivo de identificação dos anunciantes.</p>
                                <h3><span
                                    className="mt-6 block text-base text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">c) Autorização de Acesso às Fotos, Mídias e Arquivos.</span>
                                </h3>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">É necessário que o usuário autorize a Atendes a acessar suas fotos, mídias e arquivos para fins de envio de anexos em mensagens na plataforma da Atendes, bem como para manutenção do perfil do usuário.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Atendes CNPJ nº 35.225.380/0001-47</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">avenida Paulista , 171 PAVMT04 - Bela Vista – CEP 01311-000, São Paulo-SP.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}


