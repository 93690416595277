import React, { Component } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";
import {CabecalhoEmBreve} from "../componente/cabecalhoEmBreve";
export function TermoUsoPage({ history }) {
    return (
        <>
            <CabecalhoEmBreve/>

            <div className="overflow-hidden bg-white">
                <div className="relative mx-auto max-w-xl px-1 lg:max-w-7xl lg:px-1">

                    <div class="relative">
                        <div class="mx-auto text-lg mb-40 ">
                            <h1 className="text-4xl font-black tracking-tight  text-[#49000e] sm:text-4xl md:text-4xl"> TERMOS E CONDIÇÕES DE USO</h1>
                            <p className="mt-4 text-base  text-regular mt-2 leading-8 text-gray-500">Estes Termos e Condições de Uso estabelecem as regras para o uso dos nossos Serviços, incluindo a plataforma móvel, aplicativo e outros canais de contato da Atendes(denominados "Serviços"), pelos usuários.</p>
                            <div className="mt-4">
                                <h2><span className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">1. Termo de Aceitação</span></h2>
                                <p class="text-base  text-regular mt-2 leading-8 text-gray-500">Ao utilizar nossos Serviços, o usuário concorda com todos os termos e condições vigentes na data.Avisamos que esses Termos e Condições de Uso podem ser modificados a qualquer momento pela Atendes devido a mudanças na legislação, nos Serviços, uso de novas tecnologias ou outras necessidades. A utilização dos Serviços online da Atendes por qualquer usuário implica a aceitação destes Termos e Condições de Uso.</p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">2. Atualização das Informações</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes se reserva o direito de aprimorar as funcionalidades dos Serviços e de implementar novas tecnologias. Assim, os Termos de Uso poderão ser alterados, a qualquer tempo, exceto em caso de vedação legal, para incluir as modificações implementadas. Ao continuar a utilizar os nossos Serviços após alterados os Termos de Uso, você concorda com as alterações realizadas e vigentes à época do acesso.</p>

                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">3. Acesso a Conteúdo Restrito e Suspensão de Acesso</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Alguns dos nossos Serviços estão disponíveis tanto publicamente quanto com acesso restrito. Para acessar conteúdo restrito, será necessário se cadastrar e fazer login com seu usuário e senha. Lembre-se de que você é responsável pela veracidade das informações fornecidas no cadastro, e informamos que fornecer informações falsas pode causar problemas na prestação dos Serviços e afetar ou interromper seu acesso.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes pode, a qualquer momento e sem aviso prévio, suspender, cancelar ou interromper o acesso aos Serviços de acordo com a legislação aplicável.</p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes não é responsável por quaisquer danos ou problemas decorrentes de atrasos, interrupções ou bloqueios na transmissão de dados devido à conexão de internet do usuário.</p>

                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">4. Uso Adequado dos Serviços</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                    Ao utilizar os nossos Serviços, você se compromete a cumprir a legislação brasileira e as condições estabelecidas nestes Termos de Uso.
                                    É proibido disponibilizar ou compartilhar qualquer conteúdo que:
                                    <ul className="mt-2 list-disc ml-20">
                                        <li>Seja ilegal ou viole a legislação vigente;</li>
                                        <li>Viole os direitos de terceiros ou da Atendes;</li>
                                        <li>Seja falso, incorreto, impreciso ou que possa enganar outras pessoas;</li>
                                        <li>Tenha conteúdo ilegal, violento ou pornográfico ou que viole a ordem pública;</li>
                                        <li>Incite discriminação ou ódio contra grupos de pessoas;</li>
                                        <li>Seja protegido por direitos autorais de terceiros sem autorização prévia;</li>
                                        <li>Contenha vírus ou outros elementos capazes de causar danos ao sistema ou equipamentos informáticos da Atendes ou de terceiros.</li>
                                    </ul>
                                </p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">5. Responsabilidades</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                    Enquanto você usufruir dos Serviços oferecidos, é sua responsabilidade:
                                    <ul className="mt-2 list-disc ml-20">
                                        <li>Responsabilizar-se por todo ato, ação ou omissão realizado em nossos sites institucionais, plataformas e aplicativos, incluindo qualquer ato ilícito cometido por você;</li>
                                        <li>Garantir que o conteúdo gerado por você seja legal e não viole direitos de terceiros ou da Atendes;</li>
                                        <li>Arcar com a reparação de danos causados a terceiros, à Atendes, decorrentes do uso dos Serviços;</li>

                                        <li>Cuidar de seu próprio acesso à internet e arcar com os custos relacionados ao acesso à rede;</li>
                                        <li>Utilizar equipamentos adequados para realizar a conexão à internet, incluindo medidas de segurança, como antivírus e firewall;</li>
                                        <li>Utilizar os Serviços somente para as finalidades indicadas nos sites institucionais, plataformas e aplicativos.</li>

                                    </ul>
                                </p>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">
                                    Além disso, você deve estar ciente de que a Atendes não será responsável:
                                    <ul className="mt-2 list-disc ml-20">
                                        <li>Por qualquer insatisfação ou problemas relacionados aos Serviços, incluindo falhas ou dificuldade de acesso causadas por terceiros, como provedores de internet;</li>
                                        <li>Por qualquer presença de vírus ou outros elementos nocivos nos Serviços, causando danos em seus equipamentos ou documentos eletrônicos;</li>
                                        <li>Por qualquer dano ou prejuízo decorrente do conhecimento não autorizado de dados fornecidos nos Serviços, devido a falhas do usuário ou terceiros fora do controle da Atendes.</li>
                                    </ul>
                                </p>
                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">6. Propriedade Intelectual</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">A Atendes garante que todas as informações contidas em seus sites, incluindo textos, imagens, sons e aplicativos, estão em conformidade com as leis e normas que regulam os direitos de propriedade intelectual, como direitos autorais e industriais.</p>
                                <p className="text-base  text-regular mt-4 leading-8 text-gray-500">Essas informações pertencem à Atendes ou a terceiros que legalmente cederam o direito de uso. Não é permitido modificar, copiar, reproduzir ou usar essas informações para fins comerciais sem o consentimento prévio e explícito da Atendes. Ao usar nossos Serviços, você concorda em respeitar todos os direitos de propriedade intelectual contidos neles, e compreende que o direito de acesso ao Serviço não concede qualquer autorização para usar esses direitos.</p>

                                <h2><span
                                    className="mt-6 block text-xl text-left text-base font-extrabold leading-8 tracking-tight text-gray-600 ">7. Lei aplicável e resolução de conflitos</span>
                                </h2>
                                <p className="text-base  text-regular mt-2 leading-8 text-gray-500">Em caso de conflito decorrente dos termos estabelecidos nestes Termos e Condições de Uso, serão adotadas as leis brasileiras e o foro da cidade de São Paulo, Estado de São Paulo será o único competente para solução do mesmo, excluindo qualquer outro foro. Além disso, é importante observar que a utilização de Serviços e ordens emitidas fora do território brasileiro, ou resultantes de operações iniciadas no exterior, pode estar sujeita à legislação e jurisdição das autoridades dos países onde são emitidas ou iniciadas.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}


