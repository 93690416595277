import React, { Component,useEffect } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";


    export function EmBreve({ history }) {

    return (
        <>


            <div class="flex min-h-full flex-col bg-white  pb-12">
                <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center ">

                    <div class="flex h-screeen py-40">
                        <div class="m-auto">
                            <img alt="Atendes" className="w-auto h-20 " src="/img/fulllogo_transparent_nobuffer.png"/>
                            <h1 class="mt-2 text-4xl font-bold tracking-tight  text-[#49000e]">Em breve, <span class="mt-2 text-regular text-3xl text-[#49000e]">novidades</span></h1>
                            <p class="mt-2 text-base text-gray-500">marktech focada em micro e pequena empresa.</p>
                                <div className="py-8 grid grid-cols-3 gap-x-5">

                                    <div className="w-auto"><a href="/termo-uso" className="text-sm leading-6 text-gray-500 hover:text-gray-900">Termos de Uso</a></div>
                                    <div className="w-auto"><a href="/politica-cookie" className="text-sm leading-6 text-gray-500 hover:text-gray-900">Politica de Cookies</a></div>
                                    <div className="w-auto"><a href="/politica-privacidade" className="text-sm leading-6 text-gray-500 hover:text-gray-900">Politica de Privacidade</a></div>
                                </div>
                        </div>
                    </div>
                </main>

            </div>




        </>
    );
}


