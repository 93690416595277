import React, { Component } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";
import {CabecalhoEmBreve} from "../componente/cabecalhoEmBreve";
 export function Error404Page({ history }) {
     return (
            <>
                <CabecalhoEmBreve/>


                <div class="flex min-h-full flex-col bg-white  pb-12">
                    <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center ">

                        <div class="py-16">
                            <div class="text-center">
                                <p class="text-base  text-7xl font-semibold  text-[#49000e]">404</p>
                                <h1 class="mt-2 text-4xl font-bold tracking-tight  text-[#49000e] sm:text-5xl">Página não encontrada.</h1>
                                <p class="mt-2 text-base text-gray-500">Desculpe não foi possível localizar sua página.</p>
                                <div class="mt-6">
                                    <a href="/" class="text-base font-medium text-indigo-600 hover:text-indigo-500">
                                        Voltar para a home do site
                                        <span aria-hidden="true"> &rarr;</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>

                </div>



            </>
        );
}


