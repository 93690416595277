import React, { Component } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";
export function DesenvolvedorPage({ history }) {
    return (
        <>
            <Cabecalho/>
            <Rodape/>

        </>
    );
}


