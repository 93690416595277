import React, { Component } from 'react'
import { AlertaCookie } from "./alertaCookie"
export function CabecalhoEmBreve() {
    return (
        <>
            <div className="relative overflow-hidden bg-white">

                <div className="relative pt-6 pb-20">
                    <div>
                        <nav className="relative mx-auto flex max-w-7xl items-center justify-between px-6"
                             aria-label="Global">
                            <div className="flex flex-1 items-center">
                                <div className="flex w-full items-center justify-between md:w-auto">
                                    <a href="/">
                                        <span className="sr-only">Your Company</span>
                                        <img alt="Your Company" className="w-auto h-12"
                                             src="/img/fulllogo_transparent_nobuffer.png"/>
                                    </a>

                                </div>
                            </div>
                        </nav>
                </div>
            </div>
            </div>
        </>
    );
}


