import React, { Component } from 'react'
import { AlertaCookie } from "./alertaCookie"
export function Cabecalho() {
    return (
        <>
            <AlertaCookie/>
            <div className="relative overflow-hidden bg-white">

                <div className="relative pt-6 pb-20">
                    <div>
                        <nav className="relative mx-auto flex max-w-7xl items-center justify-between px-6"
                             aria-label="Global">
                            <div className="flex flex-1 items-center">
                                <div className="flex w-full items-center justify-between md:w-auto">
                                    <a href="/">
                                        <span className="sr-only">Your Company</span>
                                        <img alt="Your Company" className="w-auto h-12"
                                             src="/img/fulllogo_transparent_nobuffer.png"/>
                                    </a>
                                    <div className="-mr-2 flex items-center md:hidden">
                                        <button type="button"
                                                className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-white"
                                                aria-expanded="false">
                                            <span className="sr-only">Open main menu</span>

                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                 viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                 aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="hidden pl-20 space-x-10 md:ml-10 md:flex">
                                    <a href="/produtos"
                                       className="font-black text-lg text-[#49000e] hover:text-gray-900">Produto</a>

                                    <a href="#" className="font-black  text-lg text-[#49000e] hover:text-gray-900">Casos de sucesso</a>

                                    <a href="/afiliados"
                                       className="font-black text-lg text-[#49000e] hover:text-gray-900">Afiliado</a>


                                    <a href="#"
                                       className="font-black text-lg text-[#49000e] hover:text-gray-900">Desenvolvedor</a>
                                </div>
                            </div>
                            <div className="md:flex px-10">
                                <img className="h-8 w-8 px-1 py-1" src="./flag-brazil.svg"/>
                                <a href="#"
                                   className="py-1 font-regular text-lg text-[#49000e] hover:text-gray-900">
                                    Brasil </a>


                            </div>
                            <div className="hidden md:flex">

                            </div>
                            <div className="hidden md:flex">

                                <a href="#"
                                   className="inline-flex items-center rounded-md border border-transparent bg-[#49000e]  px-14 py-2 text-md    font-extrabold text-white hover:bg-gray-700">Log
                                    in</a>
                            </div>
                        </nav>

                        <div
                            className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden">
                            <div
                                className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                                <div className="flex items-center justify-between px-5 pt-4">
                                    <div>
                                        <img className="h-8 w-auto"
                                             src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                             alt=""/>
                                    </div>
                                    <div className="-mr-2">
                                        <button type="button"
                                                className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close menu</span>

                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                 viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                 aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M6 18L18 6M6 6l12 12"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="space-y-1 px-2 pt-2 pb-3">
                                    <a href="#"
                                       className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900">Product</a>

                                    <a href="#"
                                       className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900">Features</a>

                                    <a href="#"
                                       className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900">Marketplace</a>

                                    <a href="#"
                                       className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900">Company</a>
                                </div>
                                <a href="#"
                                   className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100">Log
                                    in</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}


